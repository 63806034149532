import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import ScrollToTop from './Components/ScrollToTop';
import { useDataContext } from './core/context/data.context';
import useTracking from './core/hooks/useTracking';
import './style/animation.css';
import './style/base.css';
import './style/buttons.css';
import './style/chat.css';
import './style/forms.css';
import './style/inputs.css';
import './style/layout.css';
import './style/loader.css';
import './style/print.css';
import './style/reset.css';
import './style/typography.css';
import './style/variables.css';

function App() {
  const { trackEvent } = useTracking();
  const { page } = useDataContext();
  const location = useLocation();

  useEffect(() => {
    if (!page) return;
    trackEvent({
      name: 'page_view',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <div className="app">
      {location.pathname.includes('user/invited') ? null : (
        <>
          <ScrollToTop />
          <Header />
        </>
      )}
      <main>
        <Outlet />
      </main>
      {location.pathname.includes('user/invited') ? null : <Footer />}
    </div>
  );
}

export default App;
