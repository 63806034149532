/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo } from 'react';

import { useUserContext } from '../context/user.context';
import useFetch from './useFetch';

interface ITrackingOptions {
  name: string;
  properties?: {
    [key: string]: any;
  };
}

const useTracking = () => {
  const { user } = useUserContext();
  const { doFetch: eventFetch } = useFetch(
    `${process.env.REACT_APP_API_URL}/analytics/track-event`,
  );
  const { doFetch: userFetch } = useFetch(`${process.env.REACT_APP_API_URL}/analytics/track-user`);

  const trackEvent = useCallback(
    ({ name, properties }: ITrackingOptions) => {
      const headers: HeadersInit = {
        'Content-Type': 'application/json',
      };

      if (user?.token) {
        headers.Authorization = `Bearer ${user.token}`;
      }

      eventFetch({
        method: 'POST',
        headers,
        body: JSON.stringify({
          name,
          properties: {
            url: window.location.href,
            ...properties,
          },
        }),
      });
    },
    [eventFetch, user?.token],
  );

  const trackUser = useCallback(() => {
    userFetch({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    });
  }, [user?.token, userFetch]);

  const value = useMemo(
    () => ({
      trackEvent,
      trackUser,
    }),
    [trackEvent, trackUser],
  );

  return value;
};

export default useTracking;
