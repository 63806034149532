import { useEffect } from 'react';

import UserMenu from '../../../Components/User/UserMenu/UserMenu';
import { useDataContext } from '../../../core/context/data.context';

function Overview() {
  const { setPage } = useDataContext();

  useEffect(() => {
    setPage('about-us');
  }, [setPage]);

  return (
    <div className="user-overview">
      <div className="wrap wrap--xs">
        <UserMenu />
      </div>
    </div>
  );
}

export default Overview;
