import { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';

import ChangeEmailForm from '../../../Components/User/ChangeEmailForm/ChangeEmailForm';
import SecurityForm from '../../../Components/User/SecurityForm/SecurityForm';
import PageTitle from '../../../common/PageTitle/PageTitle';
import ScrollSpy from '../../../common/ScrollSpy/ScrollSpy';
import { useDataContext } from '../../../core/context/data.context';
import './SecurityPrivacy.css';

function SecurityPrivacy() {
  const securityFormRef = useRef(null);
  const emailFormRef = useRef(null);
  const {
    data: { generalData },
    setPage,
  } = useDataContext();

  useEffect(() => {
    setPage('security-privacy');
  }, [setPage]);

  return (
    <>
      <Helmet>
        {!generalData?.seo?.title ? null : <title>{generalData?.seo?.title}</title>}
        {!generalData?.seo?.description ? null : (
          <meta name="description" content={generalData?.seo?.description} />
        )}
      </Helmet>
      <section className="security-privacy">
        <div className="wrap wrap--xs">
          <PageTitle title="Security & privacy" backLabel="Back" backPath="/user" />
          <div className="security-privacy__container">
            <div className="security-privacy__forms">
              <div ref={securityFormRef}>
                <SecurityForm />
              </div>
              <div ref={emailFormRef}>
                <ChangeEmailForm />
              </div>
            </div>
            <ScrollSpy
              items={[
                {
                  target: securityFormRef.current,
                  label: 'Change password',
                },
                {
                  target: emailFormRef.current,
                  label: 'Change e-mail',
                },
              ]}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default SecurityPrivacy;
