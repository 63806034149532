import { useCallback, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { A11y, Mousewheel, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/a11y';
import { Swiper, SwiperSlide } from 'swiper/react';

import CustomButton from '../../../common/CustomButton/CustomButton';
import { Icon } from '../../../common/Icon/Icon';
import Image from '../../../common/Image/Image';
import { useScreenSizeContext } from '../../../core/context/screenSize.context';
import { slugify } from '../../../core/helpers';
import useTracking from '../../../core/hooks/useTracking';
import { IArticle } from '../../../core/models';
import './ArticleSlider.css';

interface IProps {
  title: string;
  articles: IArticle[];
  darkText?: boolean;
}

function ArticleSlider({ title, articles, darkText }: IProps) {
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [init, setInit] = useState(false);
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const { isMobile } = useScreenSizeContext();
  const { trackEvent } = useTracking();

  const handleClick = useCallback(
    (article: IArticle) => {
      trackEvent({
        name: 'link_click',
        properties: { link_id: article._id, link_href: `/blog/${article.slug}` },
      });
    },
    [trackEvent],
  );

  const slides = useMemo(
    () =>
      articles?.map((article) => (
        <SwiperSlide className="article-slider__slide" key={article._id}>
          <Link
            className="article-slider__slide-link flex gap-sm"
            to={`../blog/${article.slug}`}
            target="_blank"
            onClick={() => handleClick(article)}
          >
            <div className="article-slider__slide-img-container" style={{ background: 'white' }}>
              <Image className="cover-img" img={article.img} size="small" />
            </div>
            <div className="article-slider__slide-content flex">
              <h3 className="article-slider__slide-title truncate l-2 h4">{article.title}</h3>
              {!article.authors.length ? null : (
                <div className="article-slider__item-authors truncate l-2">
                  {article.authors
                    .filter((author) => author.name)
                    .map((author) => author.name)
                    .join(', ')}
                </div>
              )}
              <div className="article-slider__item-footer truncate l-2 body-small">
                {' '}
                {new Date(article.created).toLocaleString('en-gb', {
                  day: '2-digit',
                  month: 'long',
                  year: 'numeric',
                })}
                {!article.tags.length ? null : (
                  <> &bull; {article.tags.map((tag) => tag.name).join(', ')}</>
                )}
              </div>
            </div>
          </Link>
        </SwiperSlide>
      )),
    [articles, handleClick],
  );

  if (!articles?.length) return null;

  return (
    <section className="article-slider section" style={{ color: darkText ? 'var(--text)' : '' }}>
      <div className="wrap">
        <div className="article-slider__container flex gap-lg">
          <h2 className="article-slider__title h2">{title}</h2>
          <Swiper
            className={`article-slider__slider${isLocked ? ' locked' : ''}`}
            slidesPerView="auto"
            watchOverflow
            modules={[Navigation, Mousewheel, A11y]}
            grabCursor
            mousewheel={{
              forceToAxis: true,
            }}
            spaceBetween={isMobile ? 24 : 40}
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
            onSwiper={() => setInit(true)}
            onLock={() => setIsLocked(true)}
            onUnlock={() => setIsLocked(false)}
          >
            {slides}
          </Swiper>
          <div className="article-slider__footer" hidden={isLocked}>
            <div className="article-slider__nav slider__nav">
              <CustomButton
                className="slider__arrow slider__arrow--prev"
                type="button"
                ref={prevRef}
                id={`article-slider-${slugify(title)}-prev`}
              >
                <span className="sr-only">Previous slide</span>
                <Icon.Chevron className="icon" />
              </CustomButton>
              <CustomButton
                className="slider__arrow slider__arrow--next"
                type="button"
                ref={nextRef}
                id={`article-slider-${slugify(title)}-next`}
              >
                <span className="sr-only">Next slide</span>
                <Icon.Chevron className="icon" />
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ArticleSlider;
