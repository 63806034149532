/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable no-restricted-syntax */
import Anthropic from '@anthropic-ai/sdk';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import CustomButton from '../../../common/CustomButton/CustomButton';
import CustomForm from '../../../common/CustomForm/CustomForm';
import CustomTextarea from '../../../common/CustomTextarea/CustomTextarea';
import { Icon } from '../../../common/Icon/Icon';
import Spinner from '../../../common/Spinner/Spinner';
import { useAlert } from '../../../core/context/alert.context';
import { useDataContext } from '../../../core/context/data.context';
import { useScreenSizeContext } from '../../../core/context/screenSize.context';
import { capitalize, isEmpty, slugify } from '../../../core/helpers';
import { useFormInput } from '../../../core/hooks/useFormInput';
import { ILink, TColor } from '../../../core/models';

const apiKey = process.env.REACT_APP_ANTHROPIC_API_KEY;

interface IProps {
  ai: IAnthropicAI;
}

interface IMessage {
  id: string;
  text: string;
  role: 'user' | 'assistant';
  created_at: string;
}

export interface IAnthropicAI {
  title?: string;
  btnLabel?: string;
  btnId?: string;
  initialMsg?: string;
  systemPrompt?: string;
  temperature?: number;
  model?: string;
  maxTokens?: number;
  feedback?: {
    type?: 'external' | 'scale';
    title?: string;
    cta?: ILink;
    btnText?: string;
    btnColor?: TColor;
  };
}

function Chat({ ai }: IProps) {
  const { isMobile } = useScreenSizeContext();
  const input = useFormInput('', (value) => !isEmpty(value));
  const [anthropic, setAnthropic] = useState<Anthropic | null>(null);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [chatLoading, setChatLoading] = useState(false);
  const chatMessagesRef = useRef<HTMLDivElement | null>(null);
  const { data } = useDataContext();
  const { setAlert } = useAlert();

  useEffect(() => {
    if (anthropic) return;
    setAnthropic(
      new Anthropic({
        apiKey,
        dangerouslyAllowBrowser: true,
      }),
    );
  }, [anthropic]);

  const printMessageChunk = useCallback((event: any) => {
    switch (event.type) {
      case 'message_start':
        setMessages((prevState) => [
          {
            id: event.message.id,
            text: '',
            role: 'assistant',
            created_at: new Date().toLocaleString(),
          },
          ...prevState,
        ]);
        break;
      case 'content_block_delta':
        setMessages((prevState) => {
          const newMessages = prevState.map((message) => ({ ...message }));
          if (newMessages.length) {
            const msgIdx = 0;
            if (!newMessages[msgIdx]?.text) newMessages[msgIdx].text = '';
            newMessages[msgIdx].text += event.delta.text || '';
            return newMessages;
          }
          return newMessages;
        });
        break;
      default:
        break;
    }
  }, []);

  const submit = useCallback(
    async (message?: string) => {
      if (!anthropic || chatLoading) return;
      if (isMobile) {
        input.ref?.current?.blur();
      }
      setChatLoading(true);
      if (chatMessagesRef?.current) {
        chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
      }

      if (!message) {
        setMessages((prevState) => [
          {
            id: new Date().toLocaleString(),
            text: input.value,
            role: 'user',
            created_at: new Date().toLocaleString(),
          },
          ...prevState,
        ]);
      }

      try {
        input.setValue('');
        const stream = await anthropic.messages.create({
          model: ai.model || data?.generalData?.anthropicModel || 'claude-3-5-sonnet-latest',
          max_tokens: ai.maxTokens || 1000,
          messages: [
            ...[...messages].reverse().map((msg) => ({ role: msg.role, content: msg.text })),
            {
              role: 'user',
              content: [
                {
                  type: 'text',
                  text: message || input.value,
                  cache_control: { type: 'ephemeral' },
                },
              ],
            },
          ],
          system: ai.systemPrompt
            ? [
                {
                  type: 'text',
                  text: ai.systemPrompt,
                  cache_control: { type: 'ephemeral' },
                },
              ]
            : undefined,
          stream: true,
          temperature: ai.temperature || 0,
        });
        for await (const messageStreamEvent of stream) {
          printMessageChunk(messageStreamEvent);
        }
        setChatLoading(false);
      } catch (error) {
        setChatLoading(false);
        setMessages((prevState) => [
          {
            id: new Date().toLocaleString(),
            text: 'Chat is not available now, please try again later.',
            role: 'assistant',
            created_at: new Date().toLocaleString(),
          },
          ...prevState,
        ]);
      }
    },
    [
      ai,
      anthropic,
      isMobile,
      input,
      printMessageChunk,
      data?.generalData?.anthropicModel,
      messages,
      chatLoading,
    ],
  );

  const copyChat = useCallback(() => {
    const messagesString = [...messages]
      .reverse()
      .map((message) => `${capitalize(message.role)}: ${message.text}`)
      .join('\n');
    navigator.clipboard.writeText(messagesString);
    setAlert({ message: 'Chat copied to clipboard' });
  }, [messages, setAlert]);

  useEffect(() => {
    if (!anthropic || !ai.initialMsg) return;
    submit(ai.initialMsg);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anthropic]);

  useEffect(() => {
    if (chatMessagesRef?.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  }, [messages]);

  const handleKeyDown = useCallback(
    (e: any) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        if (isMobile) {
          input.setValue((prev) => `${prev}\n`);
        } else if (e.shiftKey) {
          input.setValue((prev) => `${prev}\n`);
        } else if (input.isValid) {
          submit();
        }
      }
    },
    [input, isMobile, submit],
  );

  return (
    <div className="chat">
      {!anthropic ? null : (
        <div className="chat__container">
          <div className="chat__content">
            <div className="chat__chat">
              <div className="chat__header">
                {!ai.title ? null : <h2 className="h3">{ai.title}</h2>}
              </div>
              <div className="chat__messages" ref={chatMessagesRef}>
                {messages?.map((message) => (
                  <div
                    className={`chat__message-container${
                      message.role === 'user' ? ' chat__message-container--user' : ''
                    }`}
                    key={message.id}
                  >
                    <ReactMarkdown className="chat__message body-regular markdown">
                      {message.text.replaceAll('\n', ' \n \n').replaceAll('•', '-')}
                    </ReactMarkdown>
                  </div>
                ))}
              </div>
              <CustomForm className="chat__form" inputs={[input]} onSubmit={submit}>
                <div className="chat__input-container">
                  <CustomTextarea
                    className="chat__input"
                    input={input}
                    id={`chat-${slugify(ai.title || '')}`}
                    name="chat"
                    placeholder="Type message"
                    onKeyDown={handleKeyDown}
                  />
                  <CustomButton
                    className="chat__control"
                    type="button"
                    title="Copy chat"
                    onClick={copyChat}
                  >
                    <Icon.Copy />
                    <span className="sr-only">Copy chat</span>
                  </CustomButton>
                  <CustomButton className="chat__btn chat-btn" type="submit" disabled={chatLoading}>
                    {chatLoading ? <Spinner /> : <Icon.Arrow />}
                  </CustomButton>
                </div>
              </CustomForm>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Chat;
