import { MouseEvent, memo, useCallback } from 'react';
import { Link } from 'react-router-dom';

import useTracking from '../../core/hooks/useTracking';
import { ILink } from '../../core/models';
import { Icon } from '../Icon/Icon';

interface IProps {
  link: ILink;
  className?: string;
  target?: string;
  onClick?(e: React.MouseEvent<HTMLElement>): void;
}

const CustomLink = memo(({ link, className, target, onClick }: IProps) => {
  const { trackEvent } = useTracking();

  const handleClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      trackEvent({
        name: 'link_click',
        properties: { link_id: link.id, link_href: link.href },
      });
      if (!onClick) return;
      onClick(e);
    },
    [trackEvent, link.id, link.href, onClick],
  );

  return !link.text ? null : (
    <Link
      className={`${className ? `${className} ` : ''}btn btn--${link.color ? link.color : 'dark'}`}
      onClick={handleClick}
      to={link.href}
      target={link.newTab ? '_blank' : target}
      rel={link.newTab ? 'noreferrer nofollow' : ''}
      download={link.isDownload}
      id={link.id || ''}
    >
      {link.text}
      {!link.hasArrow ? null : <Icon.Chevron className="icon rotate-minus-90" />}
      {!link.isDownload ? null : <Icon.External className="icon" />}
    </Link>
  );
});

export default CustomLink;
