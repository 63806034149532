import { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { Helmet } from 'react-helmet-async';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import rehypeRaw from 'rehype-raw';

import ArticleBanner from '../../Components/Articles/ArticleBanner/ArticleBanner';
import PageBuilder from '../../Components/PageBuilder/PageBuilder';
import LinkRenderer from '../../common/LinkRenderer/LinkRenderer';
import PageLoader from '../../common/PageLoader/PageLoader';
import PageTitle from '../../common/PageTitle/PageTitle';
import VideoPlayer from '../../common/VideoPlayer/VideoPlayer';
import { useDataContext } from '../../core/context/data.context';
import useFetch from '../../core/hooks/useFetch';
import { Tag } from '../../core/models';
import PageNotFound from '../PageNotFound/PageNotFound';
import './Case.css';

const BASE_URL = `${process.env.REACT_APP_API_URL}`;
const APP_BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

function Case() {
  const {
    data: { generalData },
    setPage,
  } = useDataContext();
  const { slug } = useParams();
  const { result, doFetch, loading, error } = useFetch(
    `${BASE_URL}/case?slug=${slug}&populate=img&populate=case&populate=tags`,
  );
  const modalContainer: HTMLElement | null = document.getElementById('modal');

  useEffect(() => {
    doFetch();
  }, [doFetch]);

  useEffect(() => {
    if (!result) return;
    setPage(result.data?.slug);
  }, [setPage, result]);

  const markdown = useMemo(
    () =>
      !result?.data?.body
        ? null
        : result.data.body
            .replaceAll('@@SERVER_BASE_URL@@', BASE_URL)
            .replaceAll('@@CLIENT_BASE_URL@@', `${encodeURIComponent(APP_BASE_URL)}/case`),
    [result?.data?.body],
  );

  if (error) return <PageNotFound />;

  return (
    <>
      <Helmet>
        {!result?.data?.seo?.title && result?.data?.title && !generalData?.seo?.title ? null : (
          <title>
            {result?.data?.seo?.title || result?.data?.title || generalData?.seo?.title}
          </title>
        )}
        <link rel="canonical" href={`${APP_BASE_URL}/case/${slug}`} />
        {!result?.data?.seo?.description && !generalData?.seo?.description ? null : (
          <meta
            name="description"
            content={result?.data?.seo?.description || generalData?.seo?.description}
          />
        )}
      </Helmet>
      {!modalContainer ? null : createPortal(<PageLoader open={!result} />, modalContainer)}
      <article className="case">
        {!loading ? null : <div className="wrap fade-in">Loading...</div>}
        {!result?.data._id ? null : (
          <>
            <div className="wrap">
              <PageTitle
                title={result.data.title}
                backLabel={window.history.length > 1 ? 'Back' : 'Home page'}
                backPath={window.history.length > 1 ? -1 : '/'}
                info={
                  <div className="case__header-info">
                    {!result.data.tags?.length ? null : (
                      <div className="case__header-tags">
                        {result.data.tags.map((tag: Tag) => (
                          <div className="tag" key={tag._id}>
                            {tag.name}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                }
              />
            </div>
            <section className="case__content">
              <ArticleBanner text={result.data.summary} img={result.data.img} />
              <div className="wrap">
                {markdown ? (
                  <ReactMarkdown
                    className="case__body markdown"
                    components={{
                      a: LinkRenderer,
                      video: VideoPlayer,
                    }}
                    rehypePlugins={[rehypeRaw]}
                  >
                    {markdown}
                  </ReactMarkdown>
                ) : null}
              </div>
            </section>
            {!result.data.page_builder ? null : (
              <PageBuilder data={result.data.page_builder} slug={slug || ''} />
            )}
          </>
        )}
      </article>
    </>
  );
}

export default Case;
