import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useDataContext } from '../../core/context/data.context';
import './PageNotFound.css';

function PageNotFound() {
  const { setPage } = useDataContext();

  useEffect(() => {
    setPage('page-not-found');
  }, [setPage]);

  return (
    <section className="not-found">
      <div className="wrap">
        <h1 className="not-found__title h2">Oops, page not found</h1>
        <Link className="btn btn--dark" to="/">
          Go to homepage
        </Link>
      </div>
    </section>
  );
}

export default PageNotFound;
