import { forwardRef, memo, useCallback } from 'react';

import useTracking from '../../core/hooks/useTracking';

interface IProps {
  type: 'submit' | 'button';
  className?: string;
  children: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  onClick?(e: React.MouseEvent<HTMLElement>): void;
  onPointerEnter?(): void;
  onKeyDown?(): void;
  onFocus?(): void;
  id?: string;
  title?: string;
}

const CustomButton = forwardRef<HTMLButtonElement, IProps>(
  (
    {
      type,
      loading,
      disabled,
      className,
      children,
      onClick,
      onPointerEnter,
      onKeyDown,
      onFocus,
      id,
      title,
    },
    ref,
  ) => {
    const { trackEvent } = useTracking();

    const handleClick = useCallback(
      (e: React.MouseEvent<HTMLElement>) => {
        if (!onClick) return;
        trackEvent({
          name: 'btn_click',
          properties: { btn_id: id },
        });
        onClick(e);
      },
      [id, onClick, trackEvent],
    );

    return (
      <button
        className={className}
        type={type === 'submit' ? 'submit' : 'button'}
        onClick={(e) => handleClick(e)}
        onPointerEnter={onPointerEnter}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        disabled={loading || disabled}
        ref={ref}
        id={id}
        title={title}
      >
        {children}
        {loading && <span className="loader">Loading...</span>}
      </button>
    );
  },
);

const MemoizedCustomButton = memo(CustomButton);

export default MemoizedCustomButton;
