/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { AUTH_TOKEN } from '../constants';
// eslint-disable-next-line import/no-cycle
import useFetch from '../hooks/useFetch';
import { CurrentUser } from '../models';
import { CustomCheckboxInterface, CustomInputInterface } from '../models/inputs';
import { useAlert } from './alert.context';

interface UserInterface {
  user: CurrentUser;
  loginLoading: boolean;
  loginError: any;
  setUser: any;
  login: (
    email: CustomInputInterface,
    password: CustomInputInterface,
    remember: CustomCheckboxInterface,
  ) => void;
  logout: () => void;
  forceUpdate: () => void;
  updateToken: (token: string) => void;
}

const initialValue = {
  user: {
    email: '',
    token: '',
    role: 0,
    _id: '',
  },
};

const UserContext = createContext<UserInterface | null>(null);

export const useUserContext = () => useContext(UserContext) as UserInterface;

interface Props {
  children: JSX.Element;
}

export function UserContextProvider({ children }: Props) {
  const navigate = useNavigate();
  const {
    loading: loginLoading,
    doFetch: doLoginFetch,
    error: loginError,
  } = useFetch(`${process.env.REACT_APP_API_URL}/user/login`);
  const { doFetch: doUserFetch, error: userError } = useFetch(
    `${process.env.REACT_APP_API_URL}/user?populate=profile_pic&populate=expertises&populate=member_classes&populate=industries&populate=community_roles`,
  );
  const [authToken, setAuthToken] = useState(() => {
    const localToken = localStorage.getItem(AUTH_TOKEN);
    const sessionToken = sessionStorage.getItem(AUTH_TOKEN);

    if (!localToken && !sessionToken) return '';
    if (localToken) return JSON.parse(localToken);
    if (sessionToken) return JSON.parse(sessionToken);
  });
  const [user, setUser] = useState<CurrentUser>({
    email: '',
    token: authToken,
    role: 0,
    _id: '',
  });

  const { setAlert } = useAlert();

  const [update, forceUpdate] = useReducer((x) => x + 1, 0);

  const setSessionStorageToken = useCallback((token: string) => {
    sessionStorage.setItem(AUTH_TOKEN, JSON.stringify(token));
    setAuthToken(token);
  }, []);

  const setLocalSessionToken = useCallback((token: string) => {
    localStorage.setItem(AUTH_TOKEN, JSON.stringify(token));
    setAuthToken(token);
  }, []);

  const removeAuthToken = useCallback(() => {
    sessionStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(AUTH_TOKEN);
    setAuthToken('');
    setUser({
      email: '',
      token: '',
      role: 0,
      _id: '',
    });
    navigate('/');
  }, [navigate]);

  const updateToken = useCallback(
    (token: string) => {
      const localToken = localStorage.getItem(AUTH_TOKEN);
      const sessionToken = sessionStorage.getItem(AUTH_TOKEN);

      if (!localToken && !sessionToken) return;
      if (localToken) return setLocalSessionToken(token);
      if (sessionToken) return setSessionStorageToken(token);
    },
    [setLocalSessionToken, setSessionStorageToken],
  );

  useEffect(() => {
    if (!authToken) return;
    (async () => {
      const res = await doUserFetch({
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (res === 'aborted') return;
      if (res?.data) {
        setUser({
          ...res.data,
          token: authToken,
        });
      } else {
        removeAuthToken();
      }
    })();
  }, [authToken, doUserFetch, removeAuthToken, update]);

  const login = useCallback(
    async (
      email: CustomInputInterface,
      password: CustomInputInterface,
      remember: CustomCheckboxInterface,
    ) => {
      const res = await doLoginFetch({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email.value,
          password: password.value,
          source: 'PLATFORM',
        }),
      });
      if (res.data.token) {
        setAlert({
          message: res.message,
        });
        if (remember.checked) {
          setLocalSessionToken(res.data.token);
        } else {
          setSessionStorageToken(res.data.token);
        }
      }
    },
    [doLoginFetch, setAlert, setLocalSessionToken, setSessionStorageToken],
  );

  const logout = useCallback(async () => {
    if (authToken) {
      removeAuthToken();
      setUser(initialValue.user);
      setAlert({
        message: 'Logged out.',
        status: 'warning',
      });
    }
  }, [authToken, removeAuthToken, setAlert]);

  useEffect(() => {
    if (!userError) return;
    logout();
  }, [logout, userError]);

  useEffect(() => {
    const handleStorageUpdate = (e: any) => {
      const { key, newValue } = e;

      if (key !== 'authenticationToken') return;

      if (newValue) {
        setLocalSessionToken(newValue.replaceAll('"', ''));
      } else {
        removeAuthToken();
      }
    };

    window.addEventListener('storage', handleStorageUpdate);
    return () => {
      window.removeEventListener('storage', handleStorageUpdate);
    };
  }, [removeAuthToken, setLocalSessionToken]);

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      setUser,
      forceUpdate,
      loginLoading,
      loginError,
      userError,
      updateToken,
    }),
    [user, login, logout, loginLoading, loginError, userError, updateToken],
  );
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
