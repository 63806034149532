import { useCallback } from 'react';

import useTracking from '../../core/hooks/useTracking';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function LinkRenderer({ href, children }: any) {
  const { trackEvent } = useTracking();

  const handleClick = useCallback(() => {
    trackEvent({
      name: 'link_click',
      properties: { link_id: 'markdown-link', link_href: href },
    });
  }, [trackEvent, href]);

  return (
    <a href={href} target="_blank" rel="noopener noreferrer" onClick={handleClick}>
      {children}
    </a>
  );
}

export default LinkRenderer;
